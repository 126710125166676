<template>
	<section class="permission">
		<el-col :span="24" class="toolbar">
			<el-button type="primary" @click="upLoadApp" icon="el-icon-plus">新增版本</el-button>
		</el-col>
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">	
			<el-table-column prop="version" label="版本号" width="100" align="center"></el-table-column>
			<el-table-column prop="url" label="更新系统" width="120">
				<template slot-scope="scope">
					<span class="mr10" v-if="scope.row.android_url">安卓</span>
					<span v-if="scope.row.ios_url">苹果</span>
				</template>
			</el-table-column>
			<el-table-column prop="createtime" label="更新时间" sortable width="150"></el-table-column>
			<el-table-column prop="createtime" label="启用状态" width="150" align="center">
				<template slot-scope="scope">
					{{scope.row.status ? '已启用':'未启用' }}
				</template>
			</el-table-column>
			<el-table-column prop="desci" label="说明" width="250"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scope">
					<el-button type="primary" plain size="small" @click="editorInfo(scope.row)">修改</el-button>
					<el-button type="primary" plain size="small" @click="seeLink(scope.row)" >查看链接</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="查看版本信息" :visible.sync="dialogShow" width="600px" center>
			<p class="mb15">版本: {{ appInfo.version }}</p>
			<p class="mb15">说明: {{ appInfo.desci }}</p>
			<div v-if="appInfo.android_url">安卓：{{appInfo.android_url}}</div>
			<div v-if="appInfo.ios_url">苹果：{{appInfo.ios_url}}</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogShow = false">关 闭</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="nowStoreInfo.id? typeTitle + 'APP版本编辑': typeTitle + 'APP版本更新'" :visible.sync="showUpox" width="600px" center>
			<el-form label-width="120px">
				<el-form-item label="当前地址：" v-if="nowStoreInfo.android_url || nowStoreInfo.ios_url ">
					<div v-if="nowStoreInfo.android_url">安卓：{{nowStoreInfo.android_url}}</div>
					<div v-if="nowStoreInfo.ios_url">苹果：{{nowStoreInfo.ios_url}}</div>
				</el-form-item>
				<el-form-item label="Android上传：">
					<el-upload class="upload-demo" :action="uploadurl" :data="dataToken" :on-success="uploadurlApk" :beforeUpload="beforeAvatarUpload" :file-list="fileList">
						<el-button size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-form-item>
				<!-- <el-form-item label="banben">
					<el-input v-model="nowStoreInfo.url" placeholder="输入域名" class="w300"></el-input>
				</el-form-item> -->
				<el-form-item label="版本号：">
					<el-input v-model="nowStoreInfo.version" placeholder="版本号" class="w300"></el-input>
				</el-form-item>
				<el-form-item label="版本说明：">
					<el-input v-model="nowStoreInfo.desci" placeholder="版本说明" class="w300"></el-input>
				</el-form-item>
				<el-form-item label="App状态：">
					<el-switch v-model="nowStoreInfo.status" :active-value= "1" :inactive-value= "0" :disabed="nowStoreInfo.status==='1'" active-color="#13ce66" inactive-color="#ff4949" ></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="updateStore" :style="{ display: showUpdateStore}">上 传</el-button>
				<el-button type="primary" @click="appupdateStore" :style="{ display: showAppupdateStore}">更 新</el-button>
				<el-button @click="hideAddBox">取 消</el-button>
			</span>
		</el-dialog>
	</section>
</template>

<script>
	import { storeAppList, storeAppAdd, getUploadToken, storeAppUpdate } from '../../api/api';
	export default {
		name:'storeAppMan',
		data() {
			return {
				fileList: [],
				dialogShow: false,
				showUpox: false,
				appInfo:{},
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				uploadurl: process.env.VUE_APP_Qiniu, //上传地址
				dataToken: {},//上传文件需要携带的参数
				urlHead: '',
				nowStoreId: '',
				nowStoreInfo:{
					android_url: '',
					ios_url: '',
					version: '',
					desci: '',
					status: ''
				},
				showUpdateStore: '',
				showAppupdateStore: '',
				appType: 'store_app',
				typeTitle: ''
			}
		},
		created(){
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			editorInfo(data){
				this.showUpdateStore = 'none'
				this.showAppupdateStore = ''
				this.nowStoreInfo.id = data.id
				this.nowStoreInfo.android_url = data.android_url
				this.nowStoreInfo.ios_url = data.ios_url
				this.nowStoreInfo.version = data.version
				this.nowStoreInfo.desci = data.desci
				this.nowStoreInfo.status = data.status
				this.typeTitle = '店主'
				this.showUpox = true;
			},
			upLoadApp(){
				this.showUpdateStore = ''
				this.showAppupdateStore = 'none'
				this.appInfo = {};
				this.typeTitle = '店主'
				this.showUpox = true
			},
			async beforeAvatarUpload(file) {
				await getUploadToken({docType: 'apk'}).then((res) => {
					this.dataToken.token = res.data.uptoken
					this.dataToken.key = res.data.key
					this.urlHead = 'https://'+ res.data.url + '/'
				});
				var testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
				const extension = testmsg === 'zip', extension1 = testmsg === 'rar', extension2 = testmsg === 'apk';
                if(!extension && !extension1 && !extension2) {
                    this.$message.error('上传的文件只能是zip、rar或apk格式!');
                }
                return (extension || extension1 || extension2)
            },
			uploadurlApk(response){
				this.nowStoreInfo.android_url = this.urlHead + response.key
				// this.updateStore();
			},			
			//获取列表
			getList(type) {
                if(type) this.page = 1
				let para = {
					page : this.page,
					pagesize : this.pagesize,
					type: this.appType
				}				
				this.listLoading = true;
				storeAppList(para).then((res) => {
					this.listLoading = false;
					if(res.code == 1){
						this.listData = res.data;
						// this.total = res.data.count;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//上传
			updateStore(){
				if(!this.nowStoreInfo.android_url && !this.nowStoreInfo.ios_url) return this.$message.error('请选择上传文件或输入下载地址');
				this.showUpox = false;
				this.listLoading = true;
				let para = {...this.nowStoreInfo}
				para.type = this.appType
				storeAppAdd(para).then((res) => {
					if(res.code == 1){
						this.getList();
						this.hideAddBox()
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//app更新
			appupdateStore(){
				if(!this.nowStoreInfo.android_url && !this.nowStoreInfo.ios_url) return this.$message.error('请选择上传文件或输入下载地址');
				this.showUpox = false;
				this.listLoading = true;
				let para = {...this.nowStoreInfo}
				para.type = this.appType
				// console.log(this.nowStoreInfo)
				storeAppUpdate(para).then((res) => {
					if(res.code == 1){
						this.getList();
						this.hideAddBox()
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			hideAddBox(){
				this.showUpox = false
				this.clearData(this.nowStoreInfo)
				this.fileList = []
			},
			seeLink:function(data){
				this.appInfo = data;
				this.dialogShow = true
			}
		},
		mounted() {
			//获取列表数据
			this.getList();
			//获取上传文件token
			// this.getUploadTokenM();
		},
		watch:{
			'$route' : function(e1, e2){
				this.getList();
			}			
		}
	}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
</style>